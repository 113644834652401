import { library, dom } from "@fortawesome/fontawesome-svg-core";

//
// Tree-shaking will remove all but these icons from the production build
//

import {
  faSearch,
  faBars,
  faShoppingBasket,
  faTimes,
  faHome,
  faFileAlt,
  faExternalLinkAlt
} from "@fortawesome/free-solid-svg-icons";

import {
  faLinkedinIn,
  faFacebook
} from "@fortawesome/free-brands-svg-icons";

library.add(faSearch, faBars, faShoppingBasket, faTimes, faHome, faFileAlt, faExternalLinkAlt, faLinkedinIn, faFacebook);

dom.watch();
