const siteHeader = document.getElementById("site-header");
const navHeight = siteHeader.getBoundingClientRect().height;

window.onscroll = () => {
  var height = document.documentElement.scrollTop;
  if (height > navHeight) {
    siteHeader.classList.add("scrolled");
  } else if (height == 0) {
    siteHeader.classList.remove("scrolled");
  }
};
