import { StoreId } from "./store.service";

const baseUri = "/umbraco/api/Cart/";

class Request {
  constructor(data) {
    Object.assign(this, data);
    this.StoreId = this.StoreId || StoreId;
  }
}

export default class StoreClient {
  post(method, data) {
    var response = this._postJSON(baseUri + method, new Request(data));
    return response.then((res) => res.json());
  }

  _postJSON(endpoint, data) {
    var response = fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => {
      if (!res.ok) {
        res.json().then((ex) => alert(ex.Message));
        throw new Error("Encountered a problem posting to server.");
      }
      return res;
    });
    return response;
  }
}
