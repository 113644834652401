import log from "loglevel";

import "./globals";

import "./modules/responsive-images";
import "./modules/icons";
import "./modules/header";
import "./modules/cart-button";

import "bootstrap/js/src/offcanvas";
import "bootstrap/js/src/collapse";

const dynamicImports = [
  {
    name: "Cart",
    querySelector: ".cart",
    import: () => import("./modules/cart"),
  },
  {
    name: "Add-to-cart",
    querySelector: ".add-to-cart",
    import: () => import("./modules/add-to-cart"),
  },
  {
    name: "Tabs",
    querySelector: ".tab-content",
    import: () => import("./modules/tabs"),
  },
  {
    name: "Image zoom",
    querySelector: ".image-zoom",
    import: () => import("./modules/image-zoom"),
  },
  {
    name: "Dropdown",
    querySelector: ".dropdown",
    import: () => import("./modules/dropdowns"),
  },
  {
    name: "Modals",
    querySelector: ".modal",
    import: () => import("./modules/modals"),
  },
];

dynamicImports.forEach((i) => {
  if (document.querySelector(i.querySelector) !== null) {
    i.import().then(() => log.info(i.name + " module loaded"));
  }
});

