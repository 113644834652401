import CartService from "../services/cart.service";
import CreateElementFromHTML from "../utils/createElementFromHtml";

class CartCounter {
  constructor(cartIcon) {
    this.cartIcon = cartIcon;
    this.counter = CreateElementFromHTML(
      `<span class="fa-layers-counter fa-layers-bottom-left"></span>`
    );
  }

  update(count) {
    this.counter.textContent = count;
    if (count > 0 && !this.cartIcon.contains(this.counter)) {
      this.cartIcon.appendChild(this.counter);
    } else if (count < 1) {
      this.counter.remove();
    }
  }

  animate() {
    this.counter.classList.remove("animate");
    window.requestAnimationFrame(() => this.counter.classList.add("animate"));
  }

  updateAndAnimate(count) {
    this.update(count);
    this.animate();
  }
}

const cartIcons = document.querySelectorAll(".cart-icon");

for (let icon of cartIcons) {
  let counter = new CartCounter(icon);
  CartService.GetCart().then((c) => counter.update(c));
  CartService.OnChange((c) => counter.updateAndAnimate(c));
}
