import log from "loglevel";

export const GetFromScript = (scriptId) => {
  let el = document.getElementById(scriptId);
  try {
    let data = JSON.parse(el.innerText);
    return data;
  } catch (error) {
    log.error(error);
  }
};

export default {
  GetFromScript,
};
