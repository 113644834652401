import StoreClient from "./storeClient";

const client = new StoreClient();
const changeCallbacks = [];

const GetCart = () => client.post("GetCart", {});

const AddToCart = ({ ProductReference, VariantReference, Quantity }) => {
  return client
    .post("AddToCart", {
      ProductReference,
      VariantReference,
      Quantity,
    })
    .then((c) => {
      cartUpdated();
      return c;
    });
};

const UpdateCart = (orderLines) => {
  return client
    .post("UpdateCart", { OrderLineQuantities : orderLines})
    .then((c) => {
      cartUpdated();
      return c;
    });
};

const RemoveFromCart = ({ OrderLineId }) => {
  return client
    .post("RemoveFromCart", {
      OrderLineId,
    })
    .then((c) => {
      cartUpdated();
      return c;
    });
};

const cartUpdated = () =>
  GetCart().then((cart) => {
    changeCallbacks.forEach((c) => {
      c(cart);
    });
    location.reload();
  });

export default {
  GetCart,
  AddToCart,
  UpdateCart,
  RemoveFromCart,
  OnChange: (callback) => {
    changeCallbacks.push(callback);
  },
};
